<template>
    <div class="box">
        <div class="complaint">
            <h1>Denúncia</h1>
            <p>
                O atendimento do Disque Adolescente ocorre no telefone 3819-2022 e
                funciona de segunda a sexta-feira, das 11h às 14h. O serviço possui uma
                equipe de médicos, psicólogos e assistentes sociais para lidar com
                assuntos comuns no cotidiano dos jovens.
            </p>

            <p>
                www.educacao.sp.gov.br , clicar na aba, “Institucional”, no topo da
                página, e, em seguida, no link “Ouvidoria” – espaço em que encontrará
                informações sobre o funcionamento da Ouvidoria da SEE e onde poderá
                cadastrar as manifestações. Pessoalmente: Praça da República nº 53,
                Centro, São Paulo, SP A Ouvidoria pode ser acionada pelo telefone (11)
                2075 4215, das 8h às 17h, de segunda à sexta-feira.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ComplaintBullying'
    };
</script>

<style scoped>
    .box {
        max-width: 600px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .complaint h1 {
        margin-bottom: 20px;
    }

    .complaint p {
        font-size: 20px;
        text-align: justify;
        letter-spacing: 0em !important;
    }
</style>
